import dayjs from "dayjs";
import { isEmpty, orderBy } from "lodash";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

export const YAXIS_WIDTH = "60px";

export const COLORS_LIST = [
  "#fa4b42",
  "#F9B426",
  "#28B178",
  "#d568fb",
  "#91e8e1",
];

export const getTariffColorsByPrices = (prices) => {
  const colors = {};
  if (!prices) return colors;
  orderBy(prices, ["buy"], "desc").forEach((it, i) => {
    colors[it.time_period] = COLORS_LIST[i];
  });
  return colors;
};

export const getTimePeriods = (item) => {
  const bucket = Array.from({ length: 24 }, () => {});
  const flatList = [];
  item.time_periods.forEach((timeperiod) => {
    timeperiod.time_ranges.forEach((timerange) => {
      flatList.push({
        ...timeperiod,
        ...timerange,
      });
    });
  });

  orderBy(flatList, ["priority"]).forEach((flatItem) => {
    for (let i = flatItem.start_hour; i < flatItem.end_hour; i++) {
      if (isEmpty(bucket[i]) && i !== 24) {
        bucket[i] = {
          name: flatItem.name,
          start_hour: flatItem.start_hour,
          end_hour: flatItem.end_hour,
        };
      }
    }
  });

  let count = 0;
  let lastItem = {};
  const list = [];

  bucket.forEach((bucketItem, i) => {
    if (isEmpty(lastItem) || bucketItem.name === lastItem.name) {
      count = count + 1;
      lastItem = bucketItem;
      if (i === 23) {
        list.push({
          ...bucketItem,
          count: count,
        });
      }
    } else {
      if (!list[list.length - 1]) {
        list.push({
          ...lastItem,
          count: count,
        });
      } else {
        list.push({
          ...lastItem,
          start_hour: list[list.length - 1].end_hour,
          end_hour: list[list.length - 1].end_hour + count,
          count: count,
        });
      }

      count = 1;
      lastItem = bucketItem;
      if (i === 23) {
        list.push({
          ...bucketItem,
          count: count,
        });
      }
    }
  });

  return list;
};

export const getHourTime = (hour) => {
  return dayjs().set("hour", hour).format("ha");
};

export const getChartTitle = (season) => {
  let title = season.name;
  const tokens = [];
  orderBy(season.date_ranges, ["start_month"]).forEach((dt) => {
    const start = dayjs()
      .set("date", dt.start_day)
      .set("month", dt.start_month - 1)
      .format("Do MMM");

    const end = dayjs()
      .set("date", dt.end_day)
      .set("month", dt.end_month - 1)
      .format("Do MMM");

    tokens.push(`${start} to ${end}`);
  });
  title = `${title} - ${tokens.join(", ")}`;

  return title;
};
