import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty, isNil } from "lodash";
import { Header, Footer, Content, SiteSelect } from "components";
import { getSiteDetails } from "actions/site";
import { getNotificationList } from "actions/notification";
import { PageContainer, OfflineModal } from "empower-energy-owner-components";

const AuthLayout = (props) => {
  const { hideSiteSelection = false } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [offlineModal, setOfflineModal] = useState(false);
  const [offlineModalShown, setOfflineModalShown] = useState(false);
  const session = useSelector((state) => state.session.session);
  const profile = useSelector((state) => state.profile);
  const loading = useSelector((state) => state.session.loading);

  const selectedSite = useSelector((state) => state.profile?.selectedSite);
  const siteDetails = useSelector((state) => state.site?.details);

  useEffect(() => {
    if (isNil(session) && !loading) {
      navigate("/");
    }
  }, [session]);

  useEffect(() => {
    if (!isEmpty(selectedSite)) {
      dispatch(getSiteDetails(selectedSite));
    }
  }, [selectedSite]);

  useEffect(() => {
    if (!isEmpty(selectedSite)) {
      dispatch(getNotificationList(selectedSite));
    }
    const interval = setInterval(() => {
      dispatch(getNotificationList(selectedSite));
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedSite, dispatch]);

  useEffect(() => {
    if (siteDetails && !offlineModalShown) {
      if (!siteDetails.online) {
        setOfflineModalShown(true);
        setOfflineModal(!siteDetails.online);
      }
    }
  }, [siteDetails]);

  return (
    <PageContainer>
      <Header />
      <Content>
        {offlineModal && (
          <OfflineModal
            open={offlineModal}
            onClose={() => setOfflineModal(false)}
          />
        )}
        {!hideSiteSelection && profile?.sites.length > 1 && <SiteSelect />}
        <Outlet />
      </Content>
      <Footer />
    </PageContainer>
  );
};

export default AuthLayout;
