import { Box, Typography } from "@mui/material";

const PriceRange = ({ tariffColors }) => {
  return (
    <Box my={1} className="items-center">
      <Typography>Lowest Rate</Typography>
      <Box mx={1} className="items-center">
        {Object.keys(tariffColors)
          .slice()
          .reverse()
          .map((key, i) => (
            <Box
              key={i}
              sx={{
                width: "32px",
                height: "8px",
                backgroundColor: tariffColors[key],
              }}
            ></Box>
          ))}
      </Box>
      <Typography>Highest Rate</Typography>
    </Box>
  );
};

export default PriceRange;
